<template>

  <div class="text-center" >
    <header class="header">
      <a href="https://inflowaccount.co.th/">
        <img :src="require('@/assets/new_logo-1.png')" alt="Logo" class="logo" />
      </a>
      <h1></h1>
    </header>
    <h2 style="margin-top: 100px;">คำนวณเบี้ยปรับ เมื่อรายได้เกิน 1.8 ล้านบาท ต้องเข้าระบบภาษีมูลค่าเพิ่ม</h2>
    <div class="d-flex justify-content-center align-items-center width-desktop width-mobile">
  <label for="datepicker-valid" style="margin-right: 10px;"></label>
  <b-form-datepicker type="date" id="currentDate" v-model="currentDate" :state="true">
  </b-form-datepicker>
</div>

   
    <table class="table">
      <thead class="thead-dark">
        <tr>
          <th>เดือน</th>
          <th>ปี</th>
          <th>รายได้</th>
          <th>รายได้สะสม</th>
          <th>เกิน 1.8 ต้องเสีย VAT</th>
          <th>ภาษี (7%)</th>
          <th>เบี้ยปรับ 2 เท่า</th>
          <th>คำนวณเงินเพิ่ม</th>
          <th>รวมภาษีที่ต้องชำระ</th>
          <th>ภาษีที่ต้องชำระทั้งสิ้น</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(month, index) in months" :key="index">
          <td>
            <select v-model="month.selectedMonth" class="form-control">
              <option v-for="monthOption in allMonths" :key="monthOption.id" :value="monthOption.id">{{ monthOption.name }}</option>
            </select>
          </td>
          <td>
            <select v-model="month.year" class="form-control">
              <option v-for="year in yearOptions" :key="year" :value="year">{{ year }}</option>
            </select>
          </td>
          <td>     
          <input
            type="text"
            class="form-control"
            v-model="income[month.id]"
            @input="formatInput(month.id)"
            @change="calculateAndStorePayment(month.id)"
          />
          </td>
          
          <td v-if="income[month.id]" class="table-primary">{{ formatNumber(cumulativeIncome(month.id)) }}</td>
          <td v-else>-</td>
          <td v-if="calculatePayment(month.id) > 0" class="table-info">
            {{ formatNumber(calculatePayment(month.id)) }}
          </td>
          <td v-else>-</td>
          <td v-if="calculatePayment(month.id) > 0" class="table-warning">
            {{ formatNumber(calculatePayment(month.id)* 0.07) }}
          </td>
          <td v-else>-</td>
          <td v-if="calculatePayment(month.id) > 0" class="table-warning">
            {{ formatNumber(calculatePayment(month.id) * 0.07  * 2) }}
          </td>
          <td v-else>-</td>
          <td v-if="calculatePayment(month.id) > 0" class="table-warning">
            {{ formatNumber(calculateExtraPayment(month.id)) }}
          </td>
          <td v-else>-</td>
          <td v-if="calculatePayment(month.id) > 0" class="table-warning">
          {{ formatNumber(calculatePayment(month.id) * 0.07 + calculatePayment(month.id) * 0.07 * 2 + calculateExtraPayment(month.id)) }}
          </td>
          <td v-else>-</td>
          <td v-if="calculatePayment(month.id) > 0" class="table-danger">
            {{ formatNumber(totalSum) }}
          </td>
          <td v-else>-</td>

          <td>
            <button @click="deleteItem(index)" class="btn btn-danger">ลบ</button>
          </td>
  
  
        </tr>


      </tbody>
    </table>
    <div class="button-container">
      <!-- <button @click="addItem" class="btn btn-primary">เพิ่ม</button> -->
      <span class="button-spacing"></span>
      <button @click="confirm" class="btn btn-success">ยืนยัน</button>
    </div>

    <div v-if="showLink">
      <div class="showlink-desktop showlink-mobile">
      <div class="showlink-desktop1 showlink-mobile1">
        <div class="card-body">
          <h4 class="card-title">ผลการคำนวณภาษีมูลค่าเพิ่ม (VAT)</h4>
          <p class="card-text">หากผลการคำนวณภาษีมูลค่าเพิ่ม (VAT) ของคุณเกิน 1.8 ล้านบาท และมี VAT ที่ต้องเสีย <a :href="linkToShow" target="_blank">สามารถดูตัวช่วยในการขอจดทะเบียน VAT ได้ที่นี่</a></p>
          <!-- <p class="card-text"><a :href="linkToShow" target="_blank">สามารถดูตัวช่วยในการขอจดทะเบียน VAT ได้ที่นี่</a></p> -->
          <!-- <hr> -->
          <p class="card-text">หากต้องการคำแนะนำวางแผนเรื่องภาษี บัญชี สามารถทักแชทได้ที่ <a href="https://lin.ee/911BqsX" target="_blank">@ac567</a> หรือ<a class="color-tax" href="tel:0910059903">โทร.091-005-9903</a> เพราะทุกปัญหาเรื่องภาษี บัญชีใกล้ตัวคุณมากกว่าที่คุณรู้</p>
        </div>
      </div>
    </div>
    </div>
  
  </div>
</template>



<script>
export default {
  data() {
    return {
      currentDate: '',
      income: {},
      allMonths: [
        { id: 1, name: 'มกราคม' },
        { id: 2, name: 'กุมภาพันธ์' },
        { id: 3, name: 'มีนาคม' },
        { id: 4, name: 'เมษายน' },
        { id: 5, name: 'พฤษภาคม' },
        { id: 6, name: 'มิถุนายน' },
        { id: 7, name: 'กรกฎาคม' },
        { id: 8, name: 'สิงหาคม' },
        { id: 9, name: 'กันยายน' },
        { id: 10, name: 'ตุลาคม' },
        { id: 11, name: 'พฤศจิกายน' },
        { id: 12, name: 'ธันวาคม' }
      ],
      months: [],
      yearOptions: [],
      currentYear: new Date().getFullYear(),
      showLink: false,
      linkToShow: "",
      // totalExtraPayment: 0, 
      // totalExceededIncome:0,
      // totalCumulativeIncome:0,
      // totalVat:0,
    };
  },
  computed: {
  totalIncome() {
    let total = 0;
    for (const monthId in this.income) {
      total += parseInt(this.income[monthId]);
    }
    return total;
  },
  displayTotalCumulativeIncome() {
    let cumulativeTotal = 0;
    for (const monthId in this.income) {
      const income = parseInt(this.income[monthId]);
      if (!isNaN(income)) {
        cumulativeTotal += income;
      }
    }
    return cumulativeTotal;
  },
  totalExceededIncome() {
    let total = 0;
    for (const monthId in this.income) {
      total += this.calculatePayment(monthId);
    }
    return total;
  },
  totalVat() {
    return this.totalExceededIncome * 0.07;
  },
  totalExtraPayment() {
    let total = 0;
    for (const monthId in this.income) {
      total += this.calculateExtraPayment(monthId);
    }
    return total;
  },
    totalPayment() {
      let total = 0;
      for (const monthId in this.income) {
        if (monthId.endsWith('_payment')) {
          total += this.income[monthId];
        }
      }
      return total;
    },
    totalSum() {
    let sum = 0;
    for (const monthId in this.income) {
      sum += this.calculatePayment(monthId) * 0.07 + this.calculatePayment(monthId) * 0.07 * 2  + this.calculateExtraPayment(monthId);
    }
    return sum;
  },

},

  methods: {
  generateRandomLink() {
      const randomLinks = [
        "https://inflowaccount.co.th/tax-invoice/",
        "https://inflowaccount.co.th/when-should-you-register-vat/",
      ];

      const randomIndex = Math.floor(Math.random() * randomLinks.length);
      this.linkToShow = randomLinks[randomIndex];
    },


    calculateTotals() {
    this.totalCumulativeIncome = this.displayTotalCumulativeIncome;
    this.totalExceededIncome = this.totalExceededIncome();
    this.totalVat = this.totalVat();
    this.totalExtraPayment = this.totalExtraPayment();
  },

    calculateExtraMonths() {
      const exceededIncome = 1800000;
      let totalMonthsPassed = 0;
      let cumulativeIncome = 0;

      for (const monthId in this.income) {
        const income = parseInt(this.income[monthId]);
        if (!isNaN(income)) {
          cumulativeIncome += income;
          if (cumulativeIncome > exceededIncome) {
            totalMonthsPassed++;
          }
        }
      }

      return totalMonthsPassed;
    },

    getCurrentMonth(selectedMonth, selectedYear) {
  const currentDateParts = this.currentDate.split('-');
  const currentYear = parseInt(currentDateParts[0]);
  const currentMonth = parseInt(currentDateParts[1]);

  const extraMonth = currentMonth - selectedMonth;
  const extraYear = currentYear - selectedYear;

  let totalMonthsPassed = 0;
  if (extraYear > 0 || (extraYear === 0 && extraMonth >= 0)) {
    totalMonthsPassed = extraYear * 12 + extraMonth;
  }

  return totalMonthsPassed;
},

calculateExtraPayment(monthId) {
  const selectedMonth = this.months[monthId - 1].selectedMonth;
  const selectedYear = this.months[monthId - 1].year;
  const monthsPassed = this.getCurrentMonth(selectedMonth, selectedYear);
  const extraPayment = this.calculatePayment(monthId) * 0.07 * 0.015 * monthsPassed;

  return extraPayment;
},
  // calculatePayment(monthId) {
  //     const previousMonthId = monthId - 1;
  //     const exceededIncome = 1800000;
  //     const previousCumulativeIncome = this.cumulativeIncome(previousMonthId);
  //     const currentCumulativeIncome = this.cumulativeIncome(monthId);
  //     const currentIncome = parseInt(this.income[monthId]);

  //     if (!isNaN(currentIncome)) {
  //       if (currentCumulativeIncome > exceededIncome && previousCumulativeIncome <= exceededIncome) {
  //         const excessIncome = currentCumulativeIncome - exceededIncome;
  //         const payment = excessIncome ;
  //         return payment;
  //       } else if (currentCumulativeIncome > exceededIncome && previousCumulativeIncome > exceededIncome) {
  //         const excessIncome = currentIncome;
  //         const payment = excessIncome * 0.07;
  //         return payment;
  //       }
  //     }
  //     return 0;
  //   },
  calculatePayment(monthId) {
    const previousMonthId = monthId - 1;
    const previousCumulativeIncome = this.cumulativeIncome(previousMonthId);
    const currentCumulativeIncome = this.cumulativeIncome(monthId);
    const currentIncome = parseInt(this.income[monthId].replace(/,/g, ''));

    if (!isNaN(currentIncome)) {
      if (currentCumulativeIncome > 1800000 && previousCumulativeIncome <= 1800000) {
        // ถ้ารายได้สะสมเกิน 1.8 ล้านบาทและเดือนก่อนหน้าไม่เกิน
        const excessIncome = currentCumulativeIncome - 1800000;
        return excessIncome;
      } else if (currentCumulativeIncome > 1800000 && previousCumulativeIncome > 1800000) {
        // ถ้ารายได้สะสมเกิน 1.8 ล้านบาทและเดือนก่อนหน้าเกิน 1.8 ล้านบาทด้วย
        return currentIncome;
        // return currentIncome * 0.07;
      }
    }
    return 0;
  },

    getYearOptions() {
      const yearOptions = [];
      const yearsToShow = 10;
      for (let i = 0; i < yearsToShow; i++) {
        yearOptions.push(this.currentYear - i);
      }
      return yearOptions;
    },
    setYearForAll(month) {
      for (const m of this.months) {
        if (m.id === month.id) {
          continue;
        }
        m.year = month.year;
      }
    },
    cumulativeIncome(monthId) {
  let cumulativeTotal = 0;
  for (let i = 1; i <= monthId; i++) {
    const income = parseInt(this.income[i]);
    if (!isNaN(income)) {
      cumulativeTotal += income;
    }
  }
  return cumulativeTotal;
},
    formatInput(monthId) {
      const value = this.income[monthId].toString();
      const formattedValue = value.replace(/\D/g, '');
      this.income[monthId] = formattedValue;
    },
    formatNumber(number) {
    if (!isNaN(number)) {
      return number.toLocaleString();
    }
    return '-';
  },
  validateCurrentDate() {
      if (this.currentDate === '') {
        alert('กรุณากรอกวันที่');
        return false; 
      }
      return true; 
    },
  addItem() {
    const newIncomeValue = this.months.length + 1;

   
    for (const month of this.months) {
      if (!month.selectedMonth || !this.income[month.id]) {
        alert('กรุณาเลือกเดือนและกรอกช่องรายได้ให้ครบทุกช่อง');
        return; 
      }
    }
  
    const newItem = {
      id: newIncomeValue,
      selectedMonth: 1,
      year: new Date().getFullYear(),
    };
    this.income[newItem.id] = '';
    this.months.push(newItem);
    this.calculateAndStorePayment(newItem.id);
  },

  confirm() {
    for (const month of this.months) {
      if (!month.selectedMonth || !this.income[month.id]) {
        alert('กรุณาเลือกเดือนและกรอกช่องรายได้ให้ครบทุกช่อง');
        return; 
      }
    }
    this.showLink = true;
  this.generateRandomLink();
   
    const newItem = {
      id: this.months.length + 1,
      selectedMonth: 1,
      year: new Date().getFullYear(),
    };
    this.income[newItem.id] = '';
    this.months.push(newItem);
    this.calculateAndStorePayment(newItem.id);
    // this.showLink = true;
    //   this.generateRandomLink();

  },

  deleteItem(index) {
    const deletedMonthId = this.months[index].id;
    delete this.income[deletedMonthId];
    this.months.splice(index, 1);
    this.calculateAndStorePayment(deletedMonthId);
  },

    submitForm() {
      console.log(this.income);
    },
  },
  calculateAndStorePayment(monthId) {
  const payment = this.calculatePayment(monthId);
  this.income[monthId + '_payment'] = payment;
},
mounted() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    this.currentDate = `${year}-${month}-${day}`;
    this.yearOptions = this.getYearOptions();
    this.addItem();
    this.validateCurrentDate();
    this.generateRandomLink();
  },
};
</script>

<style scoped>
  table {
    margin-top: 20px;
  }
  .header {
  background-color: rgba(0, 0, 0, 0.79);
  color: white;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}


  .logo {
    width: auto; 
    height: auto;
  }
  .main-content {
    margin-top: 70px; 
  }
.width-desktop {
  width: 30%;
  height: 50px;
}
/* .width-mobile {
  width: 100%;
  height: 50px;
} */
.showlink-desktop{
display: flex;
justify-content: center;
align-items: center;
height: 100px;
padding-top: 100px;
padding-bottom: 100px;
}
.showlink-desktop1{
    margin-top: 30px; border: 0px solid rgba(0, 0, 0, 0.125) !important;    width: 800px;
    height: 200px;
    box-shadow: darkgrey 1px 1px 12px 5px;
    border: 24px solid rgba(0, 0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}
@media (max-width: 768px) {
  .showlink-desktop{
    display: none; 
}
.showlink-desktop1{
  display: none; 
}
  .width-desktop {
    display: none; 
  }
  .showlink-mobile{
    display: flex;
justify-content: center;
align-items: center;
height: 100px;
padding-top: 120px;
padding-bottom: 100px;
  }
  .showlink-mobile1{
    margin-top: 30px; border: 0px solid rgba(0, 0, 0, 0.125) !important;    width: 800px;
    height: 238px;
    box-shadow: darkgrey 1px 1px 12px 5px;
    border: 24px solid rgba(0, 0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }

  .width-mobile {
    width: 100%;
    height: 50px;
  }
}
  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  .button-spacing {
    width: 10px;
  }
</style>
<style>
  .text-center {
    text-align: center;
  }

  .d-flex {
    display: flex;
  }

  .justify-content-center {
    justify-content: center;
  }

  .align-items-center {
    align-items: center;
  }

  .table-container {
    display: flex;
    justify-content: center;
  }

  .table-wrapper {
    width: 80%;
    overflow-x: auto;
  }

  .table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }

  .table thead th,
  .table tbody td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: center;
  }

  .button-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  .form-control {
    display: block;
    width: 102% !important;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>
